import Image from "next/image";
import { Text } from "@components/ui";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { SliderSlick } from "@components/commun";
import { Settings } from "react-slick";
import style from "./SliderProducts.module.scss";

export type SliderProductsProps =
  SliceComponentProps<Content.SliderProductsSlice>;

const SliderProducts = ({ slice }: SliderProductsProps): JSX.Element => {
  const slidersettings: Settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={style.root}
    >
      <div className={style.products}>
        <SliderSlick settings={slidersettings}>
          {slice.items.map((item) => (
            <div className={style.slide}>
              <div className="">
                <Text variant="span" className={style.title}>
                  {item.title}
                </Text>
                <div className={style.thumbnail}>
                  <div className="">
                    <Image
                      src={item.feature.url ?? ""}
                      fill={true}
                      alt=""
                      objectFit="contain"
                      objectPosition="center"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </SliderSlick>
      </div>
    </section>
  );
};

export default SliderProducts;
